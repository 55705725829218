import React from 'react'
import { useEffect } from 'react'
import '../../components/styles/global.scss'
import SegmentedControl from '../../components/common/SegmentedControl'
import Layout from '../../components/common/Layout'
import { useStaticQuery, graphql } from 'gatsby'

export default function ResourcesPresentations() {
    const data = useStaticQuery(graphql`
                query Presentations {
                allDataYaml(filter: {yamlId: {eq: "presentations"}}) {
                    nodes {
                    presentations {
                        category
                        title
                        author
                        conference
                        date
                        location
                        url
                    }
                    }
                }
                }
            `),
            presentations = data.allDataYaml.nodes[0].presentations,
            categories = [...new Set(presentations.map(item => item.category))].sort()
    
    useEffect(() => {
        const   select = document.getElementById('results-select'),
                results = document.getElementById('results')

        select.addEventListener('change', (e)=>{
            const   filterWith = e.target.value,
                    filteredPresentations = presentations.filter(presentation => presentation.category === filterWith),
                    lastIndex = filteredPresentations.length - 1
            
            //  they changed the format to "December 10-13, 2022" from just 2022
            //  filter presentations by date in desc order
            //  year - month - day
            filteredPresentations.sort((a, b)=>{
                const   aString = a.date,
                        bString = b.date,
                        dateA = new Date(aString.split('-')[0] + ', ' + aString.slice(-4)),
                        dateB = new Date(bString.split('-')[0] + ', ' + bString.slice(-4))
                
                return dateB - dateA
            })

            results.innerHTML = ''
            results.classList.add('resources-results')

            for(let i = 0, len = filteredPresentations.length; i < len; i++){
                const   presentation = filteredPresentations[i],
                        link = document.createElement('a'),
                        triangle = document.createElement('span'),
                        attribution = document.createElement('p'),
                        isLast = i === lastIndex,
                        divider = document.createElement('span')

                link.target = '_blank'
                link.rel = 'noopener noreferrer'
                link.href = presentation.url
                attribution.innerHTML = presentation.author + ' ' +  presentation.conference + '; ' + presentation.date + '; ' + presentation.location + '.'
                triangle.classList.add('triangle')
                link.append(presentation.title, triangle)
                results.append(link, attribution)
                divider.classList.add('divider')
                !isLast && results.appendChild(divider)
            }
        })
    }, [])

    return (
        <Layout active='congress-presentations'
        title='PharmaEssentia Medical Affairs | Congress Presentations'
        description='Presentations from scientific congresses provided for healthcare professionals to ensure they have access to the latest clinical data.'
        socialTitle='PharmaEssentia Medical Affairs | Congress Presentations'
        socialDescription='Presentations from scientific congresses provided for healthcare professionals to ensure they have access to the latest clinical data.'
        pathname='/resources/congress-presentations'
        >

            <section>
                <div className='inner'>
                    <h1>Resources</h1>
                    <SegmentedControl additionalClasses='space-between' buttons='resources' active='congress-presentations'/>
                    <h3>Congress Presentations</h3>
                    <p>PharmaEssentia Medical Affairs is dedicated to ensuring that healthcare professionals have immediate access to the latest scientific and clinical data. The scientific presentations below are provided for educational purposes only. Some presentations or posters may discuss the use of an approved product for investigational use or an investigational agent for an indication that has not been approved by the US FDA.</p>
                    <h4 className='bold primary'>View congress posters, presentations, and symposia:</h4>
                    <div className="select-wrapper percent-33">
                        <select id='results-select' defaultValue={'DEFAULT'}>
                            <option value='DEFAULT' disabled>Select a category</option>
                            {
                                categories.map((item, index)=>(
                                    <option value={item} key={index + 'option'}>{item}</option>
                                ))
                            }
                        </select>
                        <span className="triangle-wrapper">
                            <span className="triangle"></span>
                        </span>
                    </div>
                    <div id='results'></div>
                </div>
            </section>
        </Layout>
    )

}